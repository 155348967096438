.login-form {
  height: 100%;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.login-form .p-card {
  .p-card-body {
    padding: 45px;
  }
}

.login-form .p-card {
  .p-card-body,
  .p-card-body,
  .p-card-content,
  .p-card-title,
  .p-card-subtitle {
    align-items: center;
    align-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.login-form {
  .login-button,
  .p-field,
  .p-inputtext {
    width: 100%;
  }

  label {
    text-align: left;
  }
}
