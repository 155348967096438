@import '../../scss/theme/variables';
.re-order-stops-stop-card.p-card {
  background-color: $shade600;
  color: $shade000;
  .p-card-body {
    padding: 0;
    .p-card-content {
      padding: 0;
      .stop-title {
        font-size: 1.85rem;
        font-weight: bold;
      }
      .stop-subtitle {
        display: block;
        font-size: 1.35rem;
        font-weight: bold;
        max-width: 15em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .stop-instructions {
        color: #ffe342;
      }
      .pointer {
        cursor: pointer;
      }
      .grab {
        cursor: grab;
      }
    }
  }
}
.droppable-space {
  height: 120px;
}
