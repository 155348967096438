@import '../../scss/theme/variables';

#run_component {
  height: 100%;
  .p-panel {
    .p-panel-header {
      background-color: $shade600;
      color: $shade000;
      .p-button-icon {
        font-size: 0.6rem;
      }
      span {
        cursor: pointer;
      }
    }

    .p-panel-header-icon {
      color: $shade000;
      border: 1px solid $shade000;
    }

    .p-panel-header-icon:hover {
      color: $shade600;
    }

    .p-panel-content {
      background-color: $shade300;
      padding: 1rem;

      .p-card-body {
        padding: 0.5rem 1rem;
      }

      .p-card-content {
        padding: 0.3rem 0;
      }

      .p-card-title {
        margin: 0;
      }
    }
  }

  .text-line-through {
    text-decoration: line-through 1px;
  }

  .stop-title {
    font-weight: bold;
    font-size: 1.85rem;
  }

  .stop-subtitle {
    font-weight: bold;
    font-size: 1.35rem;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15em;
  }

  .stop-instructions {
    color: #ffe342;
  }

  .account-bold {
    font-family: system-ui;
    font-weight: 700;
  }
}
