@import '../../scss//theme/variables';

#order_component {
  height: 100%;
  .p-panel {
    .p-panel-header {
      background-color: $shade600;
      color: $shade000;
    }

    .p-panel-header-icon {
      color: $shade000;
      border: 1px solid $shade000;
    }

    .p-panel-header-icon:hover {
      color: $shade600;
    }

    .p-panel-content {
      background-color: $shade300;
      padding: 1rem;

      .p-card-body {
        padding: 1rem;
      }

      .p-card-content {
        padding: 0.3rem 0;
      }

      .p-card-title {
        margin: 0;
      }
    }
  }

  .text-line-through {
    text-decoration: line-through 1px;
  }
}
