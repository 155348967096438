@import '../../scss/theme/variables';

#add_orders_to_run_component {
  height: 100%;
  .p-panel {
    .p-panel-header {
      background-color: $shade600;
      color: $shade000;
    }
  }
  .p-panel-content {
    background-color: $shade300;
    .p-card {
      .p-card-body{
        padding: 0;
        .p-card-content {
          padding: 0;
        }
      }

    }
  }
}
