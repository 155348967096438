@import '../theme/variables';

body {
  font-family: 'UntitledSans-Light';
  margin: 0;
  background-color: $bodyBackgroundColor;
}

html,
body {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  display: flex;
  flex: 1 1 100%;
  min-width: 100%;
  max-width: 100%;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: underline;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}
h5 {
  font-size: 1.25rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
  display: flex;
  flex-direction: column;
}

label {
  text-align: right;
  justify-content: flex-end;
  color: $surface900;
  font-weight: bold;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.page-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  flex: 1 1 100%;
}

.p-component,
.p-inputtext {
  font-size: 1.25rem;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff85;
  z-index: 2;
}

.overlay-spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.p-toast-top-right {
  top: 50px !important;
}
