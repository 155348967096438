@import '../../scss/theme/variables';

.vehicle-form {
  height: 100%;
  width: 100%;
}

.vehicle-form .p-card {
  width: 100%;
  //height: 500px;

  .p-card-body {
    padding: 45px;
  }
}

.vehicle-form .p-card {
  .p-card-body,
  .p-card-body,
  .p-card-content,
  .p-card-title,
  .p-card-subtitle {
    align-items: center;
    align-content: center;
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.vehicle-form {
  .p-field,
  .p-inputtext {
    width: 100%;
  }

  label{
    font-weight: normal;
  }

  .full-name-label{
    color: $primaryTextColor;
    font-size: larger;
  }

  .bg-primary{
    background: $surface200
  }
}
