#location_detail_component_header {
  .stop-title {
    font-weight: bold;
    font-size: 2rem;
  }
}
#location_detail_component_content {
  .stop-field {
    font-weight: bold;
    font-size: 1.85rem;
  }

  label {
    text-align: right;
    justify-content: flex-end;
    color: #95989b;
    font-weight: normal;
  }
}
