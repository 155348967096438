$colors: (
  'white': #ffffff,
  'black': #000000,
  'darkGrey': #6d6e71,
  'mediumGrey': #bcbec0,
  'lightGrey': #dddedf,
  'veryLightGrey': #e9ebed,
  'red': #f70202,
  'green': #29c76f,
  'blue': #5486ea,
  'peach': #ff9f42,
  'aqua': #3ec9d6,
  'crimson': #ea5455
);

// base colors
$successColor: map-get($colors, 'green') !default;
$successTextColor: map-get($colors, 'white') !default;

$warningColor: map-get($colors, 'peach') !default;
$warningTextColor: map-get($colors, 'white') !default;
$warningTextCustomColor: map-get($colors, 'black') !default;

$dangerColor: map-get($colors, 'crimson') !default;
$dangerTextColor: map-get($colors, 'white') !default;

$infoColor: map-get($colors, 'blue') !default;
$infoTextColor: map-get($colors, 'white') !default;

$helpColor: map-get($colors, 'aqua') !default;
$helpTextColor: map-get($colors, 'white') !default;

// primary colors
$primaryColor: map-get($colors, 'mediumGrey') !default;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -5%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryTextColor: map-get($colors, 'black') !default;

$highlightBg: map-get($colors, 'lightGrey');
$highlightTextColor: map-get($colors, 'black');

//reused color variables
$shade000: map-get($colors, 'white') !default;
$shade100: map-get($colors, 'veryLightGrey') !default;
$shade200: map-get($colors, 'lightGrey') !default;
$shade300: map-get($colors, 'veryLightGrey') !default;
$shade400: map-get($colors, 'mediumGrey') !default;
$shade500: map-get($colors, 'mediumGrey') !default;
$shade600: map-get($colors, 'darkGrey') !default;
$shade700: map-get($colors, 'black') !default;

$surface50: scale-color($primaryColor, $lightness: 95%) !default;
$surface000: scale-color($primaryColor, $lightness: 90%) !default;
$surface100: scale-color($primaryColor, $lightness: 80%) !default;
$surface200: scale-color($primaryColor, $lightness: 70%) !default;
$surface300: scale-color($primaryColor, $lightness: 60%) !default;
$surface400: scale-color($primaryColor, $lightness: 50%) !default;
$surface500: scale-color($primaryColor, $lightness: 40%) !default;
$surface600: scale-color($primaryColor, $lightness: 30%) !default;
$surface700: $primaryColor !default;
$surface800: scale-color($primaryColor, $lightness: -10%) !default;
$surface900: scale-color($primaryColor, $lightness: -20%) !default;

//global
$fontFamily: UntitledSans-Light !default;
$fontSize: 1rem !default;
$fontWeight: normal !default;
$textColor: $shade700 !default;
$textSecondaryColor: $shade600 !default;
$borderRadius: 6px !default;
$transitionDuration: 0.2s !default;
$formElementTransition: background-color $transitionDuration,
  color $transitionDuration, border-color $transitionDuration,
  box-shadow $transitionDuration !default;
$actionIconTransition: background-color $transitionDuration,
  color $transitionDuration, box-shadow $transitionDuration !default;
$listItemTransition: box-shadow $transitionDuration !default;
$primeIconFontSize: 1rem !default;
$divider: 1px solid $shade300 !default;
$inlineSpacing: 0.5rem !default;
$disabledOpacity: 0.6 !default;
$maskBg: rgba(0, 0, 0, 0.4) !default;
$loadingIconFontSize: 2rem !default;
$errorColor: map-get($colors, 'red') !default;

$bodyBackgroundColor: $surface50;
$menuBackgroundColor: map-get($colors, 'veryLightGrey');
$higlightColor: map-get($colors, 'lightGrey');
$menuIconColor: map-get($colors, 'black');
$menuIconBgColor: map-get($colors, 'white');
$menuIconBgColorHover: map-get($colors, 'lightGrey');

//scale
$scaleSM: 0.875 !default;
$scaleLG: 1.25 !default;

//focus
$focusOutlineColor: $primaryLightColor !default;
$focusOutline: 0 none !default;
$focusOutlineOffset: 0 !default;
$focusShadow: 0 0 0 1px $focusOutlineColor !default;

//action icons
$actionIconWidth: 2rem !default;
$actionIconHeight: 2rem !default;
$actionIconBg: transparent !default;
$actionIconBorder: 0 none !default;
$actionIconColor: $shade600 !default;
$actionIconHoverBg: $shade200 !default;
$actionIconHoverBorderColor: transparent !default;
$actionIconHoverColor: $shade700 !default;
$actionIconBorderRadius: 50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: 0.75rem 0.75rem !default;
$inputTextFontSize: 1rem !default;

$inputBg: $shade000 !default;
$inputTextColor: $shade700 !default;
$inputIconColor: $shade600 !default;
$inputBorder: 1px solid $shade400 !default;
$inputHoverBorderColor: $primaryColor !default;
$inputFocusBorderColor: $primaryColor !default;
$inputErrorBorderColor: $errorColor !default;
$inputPlaceholderTextColor: $shade600 !default;
$inputFilledBg: $shade200 !default;
$inputFilledHoverBg: $inputFilledBg !default;
$inputFilledFocusBg: $shade000 !default;

//input groups
$inputGroupBg: $shade200 !default;
$inputGroupTextColor: $shade600 !default;
$inputGroupAddOnMinWidth: 3rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: $shade000 !default;
$inputListTextColor: $shade700 !default;
$inputListBorder: $inputBorder !default;
$inputListPadding: 0.75rem 0 !default;
$inputListItemPadding: 0.75rem 1.25rem !default;
$inputListItemBg: transparent !default;
$inputListItemTextColor: $shade700 !default;
$inputListItemHoverBg: $shade200 !default;
$inputListItemTextHoverColor: $shade700 !default;
$inputListItemBorder: 0 none !default;
$inputListItemBorderRadius: 0 !default;
$inputListItemMargin: 0 !default;
$inputListItemFocusShadow: inset 0 0 0 1px $focusOutlineColor !default;
$inputListHeaderPadding: 0.75rem 1.25rem !default;
$inputListHeaderMargin: 0 !default;
$inputListHeaderBg: $shade100 !default;
$inputListHeaderTextColor: $shade600 !default;
$inputListHeaderBorder: 1px solid $shade300 !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputListBg !default;
$inputOverlayHeaderBg: $inputListHeaderBg !default;
$inputOverlayBorder: 0 none !default;
$inputOverlayShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !default;

//password
$passwordMeterBg: $shade300 !default;
$passwordWeakBg: map-get($colors, 'crimson') !default;
$passwordMediumBg: map-get($colors, 'peach') !default;
$passwordStrongBg: map-get($colors, 'green') !default;

//button
$buttonPadding: 0.75rem 1.25rem !default;
$buttonIconOnlyWidth: 2.3rem !default;
$buttonIconOnlyPadding: 0.75rem 0 !default;
$buttonBg: $primaryColor !default;
$buttonTextColor: $primaryTextColor !default;
$buttonBorder: 1px solid $primaryColor !default;
$buttonHoverBg: $primaryDarkColor !default;
$buttonTextHoverColor: $primaryTextColor !default;
$buttonHoverBorderColor: $primaryDarkColor !default;
$buttonActiveBg: $primaryDarkerColor !default;
$buttonTextActiveColor: $primaryTextColor !default;
$buttonActiveBorderColor: $primaryDarkerColor !default;
$raisedButtonShadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !default;
$roundedButtonBorderRadius: 2rem !default;

$textButtonHoverBgOpacity: 0.04 !default;
$textButtonActiveBgOpacity: 0.16 !default;
$outlinedButtonBorder: 1px solid !default;
$plainButtonTextColor: $textSecondaryColor !default;
$plainButtonHoverBgColor: $shade200 !default;
$plainButtonActiveBgColor: $shade300 !default;

$secondaryButtonBg: map-get($colors, 'lightGrey') !default;
$secondaryButtonTextColor: map-get($colors, 'white') !default;
$secondaryButtonBorder: 1px solid $secondaryButtonBg !default;
$secondaryButtonHoverBg: scale-color(
  $secondaryButtonBg,
  $lightness: -10%
) !default;
$secondaryButtonTextHoverColor: $secondaryButtonTextColor !default;
$secondaryButtonHoverBorderColor: scale-color(
  $secondaryButtonBg,
  $lightness: -10%
) !default;
$secondaryButtonActiveBg: scale-color(
  $secondaryButtonBg,
  $lightness: -20%
) !default;
$secondaryButtonTextActiveColor: $secondaryButtonTextColor !default;
$secondaryButtonActiveBorderColor: scale-color(
  $secondaryButtonBg,
  $lightness: -20%
) !default;
$secondaryButtonFocusShadow: 0 0 0 0.2rem
  scale-color($secondaryButtonBg, $lightness: 60%) !default;

$infoButtonBg: map-get($colors, 'blue') !default;
$infoButtonTextColor: map-get($colors, 'white') !default;
$infoButtonBorder: 1px solid $infoButtonBg !default;
$infoButtonHoverBg: scale-color($infoButtonBg, $lightness: -10%) !default;
$infoButtonTextHoverColor: $infoButtonTextColor !default;
$infoButtonHoverBorderColor: scale-color(
  $infoButtonBg,
  $lightness: -10%
) !default;
$infoButtonActiveBg: scale-color($infoButtonBg, $lightness: -20%) !default;
$infoButtonTextActiveColor: $infoButtonTextColor !default;
$infoButtonActiveBorderColor: scale-color(
  $infoButtonBg,
  $lightness: -20%
) !default;
$infoButtonFocusShadow: 0 0 0 0.2rem scale-color($infoButtonBg, $lightness: 60%) !default;

$successButtonBg: map-get($colors, 'green') !default;
$successButtonTextColor: map-get($colors, 'white') !default;
$successButtonBorder: 1px solid $successButtonBg !default;
$successButtonHoverBg: scale-color($successButtonBg, $lightness: -10%) !default;
$successButtonTextHoverColor: $successButtonTextColor !default;
$successButtonHoverBorderColor: scale-color(
  $successButtonBg,
  $lightness: -10%
) !default;
$successButtonActiveBg: scale-color(
  $successButtonBg,
  $lightness: -20%
) !default;
$successButtonTextActiveColor: $successButtonTextColor !default;
$successButtonActiveBorderColor: scale-color(
  $successButtonBg,
  $lightness: -20%
) !default;
$successButtonFocusShadow: 0 0 0 0.2rem
  scale-color($successButtonBg, $lightness: 60%) !default;

$warningButtonBg: map-get($colors, 'peach') !default;
$warningButtonTextColor: map-get($colors, 'white') !default;
$warningButtonBorder: 1px solid $warningButtonBg !default;
$warningButtonHoverBg: scale-color($warningButtonBg, $lightness: -10%) !default;
$warningButtonTextHoverColor: $warningButtonTextColor !default;
$warningButtonHoverBorderColor: scale-color(
  $warningButtonBg,
  $lightness: -10%
) !default;
$warningButtonActiveBg: scale-color(
  $warningButtonBg,
  $lightness: -20%
) !default;
$warningButtonTextActiveColor: $warningButtonTextColor !default;
$warningButtonActiveBorderColor: scale-color(
  $warningButtonBg,
  $lightness: -20%
) !default;
$warningButtonFocusShadow: 0 0 0 0.2rem
  scale-color($warningButtonBg, $lightness: 60%) !default;

$helpButtonBg: map-get($colors, 'aqua') !default;
$helpButtonTextColor: map-get($colors, 'white') !default;
$helpButtonBorder: 1px solid $helpButtonBg !default;
$helpButtonHoverBg: scale-color($helpButtonBg, $lightness: -10%) !default;
$helpButtonTextHoverColor: $helpButtonTextColor !default;
$helpButtonHoverBorderColor: scale-color(
  $helpButtonBg,
  $lightness: -10%
) !default;
$helpButtonActiveBg: scale-color($helpButtonBg, $lightness: -20%) !default;
$helpButtonTextActiveColor: $helpButtonTextColor !default;
$helpButtonActiveBorderColor: scale-color(
  $helpButtonBg,
  $lightness: -20%
) !default;
$helpButtonFocusShadow: 0 0 0 0.2rem scale-color($helpButtonBg, $lightness: 60%) !default;

$dangerButtonBg: map-get($colors, 'crimson') !default;
$dangerButtonTextColor: map-get($colors, 'white') !default;
$dangerButtonBorder: 1px solid $dangerButtonBg !default;
$dangerButtonHoverBg: scale-color($dangerButtonBg, $lightness: -10%) !default;
$dangerButtonTextHoverColor: $dangerButtonTextColor !default;
$dangerButtonHoverBorderColor: scale-color(
  $dangerButtonBg,
  $lightness: -10%
) !default;
$dangerButtonActiveBg: scale-color($dangerButtonBg, $lightness: -20%) !default;
$dangerButtonTextActiveColor: $dangerButtonTextColor !default;
$dangerButtonActiveBorderColor: scale-color(
  $dangerButtonBg,
  $lightness: -20%
) !default;
$dangerButtonFocusShadow: 0 0 0 0.2rem
  scale-color($dangerButtonBg, $lightness: 60%) !default;

$linkButtonColor: $primaryDarkerColor !default;
$linkButtonHoverColor: $primaryDarkerColor !default;
$linkButtonTextHoverDecoration: underline !default;
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor !default;

//checkbox
$checkboxWidth: 22px !default;
$checkboxHeight: 22px !default;
$checkboxBorder: 2px solid $shade400 !default;
$checkboxIconFontSize: 14px !default;
$checkboxActiveBorderColor: $primaryColor !default;
$checkboxActiveBg: $primaryColor !default;
$checkboxIconActiveColor: $primaryTextColor !default;
$checkboxActiveHoverBg: $primaryDarkerColor !default;
$checkboxIconActiveHoverColor: $primaryTextColor !default;
$checkboxActiveHoverBorderColor: $primaryDarkerColor !default;

//radiobutton
$radiobuttonWidth: 22px !default;
$radiobuttonHeight: 22px !default;
$radiobuttonBorder: 2px solid $shade400 !default;
$radiobuttonIconSize: 12px !default;
$radiobuttonActiveBorderColor: $primaryColor !default;
$radiobuttonActiveBg: $primaryColor !default;
$radiobuttonIconActiveColor: $primaryTextColor !default;
$radiobuttonActiveHoverBg: $primaryDarkerColor !default;
$radiobuttonIconActiveHoverColor: $primaryTextColor !default;
$radiobuttonActiveHoverBorderColor: $primaryDarkerColor !default;

//colorpicker
$colorPickerPreviewWidth: 2rem !default;
$colorPickerPreviewHeight: 2rem !default;
$colorPickerBg: $surface800 !default;
$colorPickerBorderColor: $surface900 !default;
$colorPickerHandleColor: $shade000 !default;

//togglebutton
$toggleButtonBg: $inputBg !default;
$toggleButtonBorder: 1px solid $shade400 !default;
$toggleButtonTextColor: $shade700 !default;
$toggleButtonIconColor: $shade600 !default;
$toggleButtonHoverBg: $shade200 !default;
$toggleButtonHoverBorderColor: $shade400 !default;
$toggleButtonTextHoverColor: $shade700 !default;
$toggleButtonIconHoverColor: $shade600 !default;
$toggleButtonActiveBg: $primaryColor !default;
$toggleButtonActiveBorderColor: $primaryColor !default;
$toggleButtonTextActiveColor: $primaryTextColor !default;
$toggleButtonIconActiveColor: $primaryTextColor !default;
$toggleButtonActiveHoverBg: $primaryDarkColor !default;
$toggleButtonActiveHoverBorderColor: $primaryDarkColor !default;
$toggleButtonTextActiveHoverColor: $primaryTextColor !default;
$toggleButtonIconActiveHoverColor: $primaryTextColor !default;

//inplace
$inplacePadding: $inputPadding !default;
$inplaceHoverBg: $shade200 !default;
$inplaceTextHoverColor: $shade700 !default;

//rating
$ratingIconFontSize: 1.143rem !default;
$ratingCancelIconColor: map-get($colors, 'crimson') !default;
$ratingCancelIconHoverColor: scale-color(
  map-get($colors, 'crimson'),
  $lightness: -10%
) !default;
$ratingStarIconOffColor: $shade700 !default;
$ratingStarIconOnColor: $primaryColor !default;
$ratingStarIconHoverColor: $primaryColor !default;

//slider
$sliderBg: $shade300 !default;
$sliderBorder: 0 none !default;
$sliderHorizontalHeight: 0.286rem !default;
$sliderVerticalWidth: 0.286rem !default;
$sliderHandleWidth: 1.143rem !default;
$sliderHandleHeight: 1.143rem !default;
$sliderHandleBg: $shade000 !default;
$sliderHandleBorder: 2px solid $primaryColor !default;
$sliderHandleBorderRadius: 50% !default;
$sliderHandleHoverBorderColor: $primaryColor !default;
$sliderHandleHoverBg: $primaryColor !default;
$sliderRangeBg: $primaryColor !default;

//calendar
$calendarTableMargin: 0.5rem 0 !default;
$calendarPadding: 0.5rem !default;
$calendarBg: linear-gradient(
  90deg,
  $primaryColor 0%,
  scale-color($primaryColor, $red: 25%) 100%
) !default;
$calendarInlineBg: $calendarBg !default;
$calendarTextColor: $primaryTextColor !default;
$calendarBorder: 0 none !default;
$calendarOverlayBorder: $inputOverlayBorder !default;

$calendarHeaderPadding: 0.5rem !default;
$calendarHeaderBg: transparent !default;
$calendarInlineHeaderBg: $calendarBg !default;
$calendarHeaderBorder: 1px solid rgba(255, 255, 255, 0.2) !default;
$calendarHeaderTextColor: $primaryTextColor !default;
$calendarHeaderFontWeight: 700 !default;
$calendarHeaderCellPadding: 0.5rem !default;

$calendarCellDatePadding: 0.5rem !default;
$calendarCellDateWidth: 2.5rem !default;
$calendarCellDateHeight: 2.5rem !default;
$calendarCellDateBorderRadius: 50% !default;
$calendarCellDateBorder: 1px solid transparent !default;
$calendarCellDateHoverBg: rgba(255, 255, 255, 0.2) !default;
$calendarCellDateTodayBg: rgba(255, 255, 255, 0.1) !default;
$calendarCellDateTodayBorderColor: transparent !default;
$calendarCellDateTodayTextColor: $primaryTextColor !default;

$calendarButtonBarPadding: 1rem 0 !default;
$calendarTimePickerPadding: 0.5rem !default;
$calendarTimePickerElementPadding: 0 0.5rem !default;
$calendarTimePickerTimeFontSize: 1.25rem !default;

$calendarBreakpoint: 769px !default;
$calendarCellDatePaddingSM: 0 !default;

//input switch
$inputSwitchWidth: 3rem !default;
$inputSwitchHeight: 1.75rem !default;
$inputSwitchBorderRadius: 30px !default;
$inputSwitchHandleWidth: 1.25rem !default;
$inputSwitchHandleHeight: 1.25rem !default;
$inputSwitchHandleBorderRadius: 50% !default;
$inputSwitchSliderPadding: 0.25rem !default;
$inputSwitchSliderOffBg: $shade400 !default;
$inputSwitchHandleOffBg: $shade000 !default;
$inputSwitchSliderOffHoverBg: scale-color($shade400, $lightness: -10%) !default;
$inputSwitchSliderOnBg: $successColor !default;
$inputSwitchSliderOnHoverBg: scale-color(
  $successColor,
  $lightness: -10%
) !default;
$inputSwitchHandleOnBg: $shade000 !default;

//panel
$panelHeaderBorder: 1px solid $shade300 !default;
$panelHeaderBg: $shade100 !default;
$panelHeaderTextColor: $shade600 !default;
$panelHeaderFontWeight: 700 !default;
$panelHeaderPadding: 1.25rem !default;
$panelToggleableHeaderPadding: 0.75rem 1.25rem !default;

$panelHeaderHoverBg: $shade200 !default;
$panelHeaderHoverBorderColor: $shade300 !default;
$panelHeaderTextHoverColor: $shade600 !default;

$panelContentBorder: 1px solid $shade300 !default;
$panelContentBg: $shade000 !default;
$panelContentTextColor: $shade700 !default;
$panelContentPadding: 1.25rem !default;

$panelFooterBorder: 1px solid $shade300 !default;
$panelFooterBg: $shade000 !default;
$panelFooterTextColor: $shade700 !default;
$panelFooterPadding: 0.75rem 1.25rem !default;

//accordion
$accordionSpacing: 4px !default;
$accordionHeaderBorder: $panelHeaderBorder !default;
$accordionHeaderBg: $panelHeaderBg !default;
$accordionHeaderTextColor: $panelHeaderTextColor !default;
$accordionHeaderFontWeight: $panelHeaderFontWeight !default;
$accordionHeaderPadding: $panelHeaderPadding !default;

$accordionHeaderHoverBg: $shade200 !default;
$accordionHeaderHoverBorderColor: $shade300 !default;
$accordionHeaderTextHoverColor: $panelHeaderTextColor !default;

$accordionHeaderActiveBg: $panelHeaderBg !default;
$accordionHeaderActiveBorderColor: $shade300 !default;
$accordionHeaderTextActiveColor: $shade700 !default;

$accordionHeaderActiveHoverBg: $shade200 !default;
$accordionHeaderActiveHoverBorderColor: $shade300 !default;
$accordionHeaderTextActiveHoverColor: $shade700 !default;

$accordionContentBorder: $panelContentBorder !default;
$accordionContentBg: $panelContentBg !default;
$accordionContentTextColor: $panelContentTextColor !default;
$accordionContentPadding: $panelContentPadding !default;

//tabview
$tabviewNavBorder: 1px solid $shade300 !default;
$tabviewNavBorderWidth: 0 0 2px 0 !default;
$tabviewNavBg: $shade000 !default;

$tabviewHeaderSpacing: 0 !default;
$tabviewHeaderBorder: solid $shade300 !default;
$tabviewHeaderBorderWidth: 0 0 2px 0 !default;
$tabviewHeaderBorderColor: transparent transparent $shade300 transparent !default;
$tabviewHeaderBg: $shade000 !default;
$tabviewHeaderTextColor: $shade600 !default;
$tabviewHeaderFontWeight: $panelHeaderFontWeight !default;
$tabviewHeaderPadding: $panelHeaderPadding !default;
$tabviewHeaderMargin: 0 0 -2px 0 !default;

$tabviewHeaderHoverBg: $shade000 !default;
$tabviewHeaderHoverBorderColor: $shade500 !default;
$tabviewHeaderTextHoverColor: $shade600 !default;

$tabviewHeaderActiveBg: $shade000 !default;
$tabviewHeaderActiveBorderColor: $primaryColor !default;
$tabviewHeaderTextActiveColor: $primaryColor !default;

$tabviewContentBorder: 0 none !default;
$tabviewContentBg: $panelContentBg !default;
$tabviewContentTextColor: $panelContentTextColor !default;
$tabviewContentPadding: $panelContentPadding !default;

//upload
$fileUploadProgressBarHeight: 0.25rem !default;
$fileUploadContentPadding: 2rem 1rem !default;

//scrollpanel
$scrollPanelTrackBorder: 0 none !default;
$scrollPanelTrackBg: $shade100 !default;

//card
$cardBodyPadding: 1.25rem !default;
$cardTitleFontSize: 1.5rem !default;
$cardTitleFontWeight: 700 !default;
$cardSubTitleFontWeight: 400 !default;
$cardSubTitleColor: $shade600 !default;
$cardContentPadding: 1.25rem 0 !default;
$cardFooterPadding: 1.25rem 0 0 0 !default;
$cardShadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 1px 3px 0 rgba(0, 0, 0, 0.12) !default;

//editor
$editorToolbarBg: $panelHeaderBg !default;
$editorToolbarBorder: $panelHeaderBorder !default;
$editorToolbarPadding: $panelHeaderPadding !default;
$editorToolbarIconColor: $textSecondaryColor !default;
$editorToolbarIconHoverColor: $textColor !default;
$editorIconActiveColor: $primaryColor !default;
$editorContentBorder: $panelContentBorder !default;
$editorContentBg: $panelContentBg !default;

//paginator
$paginatorBg: $shade100 !default;
$paginatorTextColor: $shade600 !default;
$paginatorBorder: solid $shade200 !default;
$paginatorBorderWidth: 0 !default;
$paginatorPadding: 0.3rem 1rem !default;
$paginatorElementWidth: $buttonIconOnlyWidth !default;
$paginatorElementHeight: $buttonIconOnlyWidth !default;
$paginatorElementBg: transparent !default;
$paginatorElementBorder: 0 none !default;
$paginatorElementIconColor: $shade600 !default;
$paginatorElementHoverBg: $shade200 !default;
$paginatorElementHoverBorderColor: transparent !default;
$paginatorElementIconHoverColor: $shade700 !default;
$paginatorElementBorderRadius: 50% !default;
$paginatorElementMargin: 0.143rem !default;
$paginatorElementPadding: 0 !default;

//table
$tableHeaderBorder: 1px solid $shade300 !default;
$tableHeaderBorderWidth: 1px 0 1px 0 !default;
$tableHeaderBg: $shade100 !default;
$tableHeaderTextColor: $shade600 !default;
$tableHeaderFontWeight: 700 !default;
$tableHeaderPadding: 1rem 1rem !default;

$tableHeaderCellPadding: 1rem 1rem !default;
$tableHeaderCellBg: $shade100 !default;
$tableHeaderCellTextColor: $shade600 !default;
$tableHeaderCellFontWeight: 700 !default;
$tableHeaderCellBorder: 1px solid $shade300 !default;
$tableHeaderCellBorderWidth: 0 0 1px 0 !default;
$tableHeaderCellHoverBg: $shade200 !default;
$tableHeaderCellTextHoverColor: $shade600 !default;
$tableHeaderCellIconColor: $shade600 !default;
$tableHeaderCellIconHoverColor: $shade600 !default;
$tableHeaderCellHighlightBg: $highlightBg !default;
$tableHeaderCellHighlightTextColor: $highlightTextColor !default;
$tableHeaderCellHighlightHoverBg: $highlightBg !default;
$tableHeaderCellHighlightTextHoverColor: $highlightTextColor !default;
$tableSortableColumnBadgeSize: 1.143rem !default;

$tableBodyRowBg: $shade000 !default;
$tableBodyRowTextColor: $shade700 !default;
$tableBodyRowEvenBg: scale-color($tableBodyRowBg, $lightness: -1%) !default;
$tableBodyRowHoverBg: $shade200 !default;
$tableBodyRowTextHoverColor: $shade700 !default;
$tableBodyCellBorder: 1px solid $shade300 !default;
$tableBodyCellBorderWidth: 0 0 1px 0 !default;
$tableBodyCellPadding: 1rem 1rem !default;

$tableFooterCellPadding: 1rem 1rem !default;
$tableFooterCellBg: $shade100 !default;
$tableFooterCellTextColor: $shade600 !default;
$tableFooterCellFontWeight: 700 !default;
$tableFooterCellBorder: 1px solid $shade300 !default;
$tableFooterCellBorderWidth: 0 0 1px 0 !default;
$tableResizerHelperBg: $primaryColor !default;
$tableDragHelperBg: rgba($primaryColor, 0.16) !default;

$tableFooterBorder: 1px solid $shade300 !default;
$tableFooterBorderWidth: 0 0 1px 0 !default;
$tableFooterBg: $shade100 !default;
$tableFooterTextColor: $shade600 !default;
$tableFooterFontWeight: 700 !default;
$tableFooterPadding: 1rem 1rem !default;

$tableCellContentAlignment: left !default;
$tableTopPaginatorBorderWidth: 0 0 1px 0 !default;
$tableBottomPaginatorBorderWidth: 0 0 1px 0 !default;

$tableScaleSM: 0.5 !default;
$tableScaleLG: 1.25 !default;

//dataview
$dataViewContentPadding: 0 !default;
$dataViewContentBorder: 0 none !default;
$dataViewListItemBorder: solid $shade300 !default;
$dataViewListItemBorderWidth: 0 0 1px 0 !default;

//orderlist, picklist
$orderListBreakpoint: 769px !default;
$pickListBreakpoint: 769px !default;

//schedule
$fullCalendarEventBg: $primaryDarkColor !default;
$fullCalendarEventBorder: 1px solid $primaryDarkColor !default;
$fullCalendarEventTextColor: $primaryTextColor !default;

//tree
$treeContainerPadding: 0.286rem !default;
$treeNodePadding: 0.143rem !default;
$treeNodeContentPadding: 0.5rem !default;
$treeNodeChildrenPadding: 0 0 0 1rem !default;
$treeNodeIconColor: $shade600 !default;

//timeline
$timelineVerticalEventContentPadding: 0 1rem;
$timelineHorizontalEventContentPadding: 1rem 0;
$timelineEventMarkerWidth: 1rem;
$timelineEventMarkerHeight: 1rem;
$timelineEventMarkerBorderRadius: 50%;
$timelineEventMarkerBorder: 2px solid $primaryColor;
$timelineEventMarkerBackground: $primaryTextColor;
$timelineEventConnectorSize: 2px;
$timelineEventColor: $shade300;

//org chart
$organizationChartConnectorColor: $shade300 !default;

//message
$messageMargin: 1rem 0 !default;
$messagePadding: 1.25rem 1.75rem !default;
$messageBorderWidth: 0 0 0 6px !default;
$messageIconFontSize: 1.5rem !default;
$messageTextFontSize: 1rem !default;
$messageTextFontWeight: 400 !default;

//inline message
$inlineMessagePadding: $inputPadding !default;
$inlineMessageMargin: 0 !default;
$inlineMessageIconFontSize: 1rem !default;
$inlineMessageTextFontSize: 1rem !default;
$inlineMessageBorderWidth: 0px !default;

//toast
$toastIconFontSize: 2rem !default;
$toastMessageTextMargin: 0 0 0 1rem !default;
$toastMargin: 0 0 1rem 0 !default;
$toastPadding: 1rem !default;
$toastBorderWidth: 0 0 0 6px !default;
$toastShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !default;
$toastOpacity: 0.9 !default;
$toastTitleFontWeight: 700 !default;
$toastDetailMargin: $inlineSpacing 0 0 0 !default;

//severities
$infoMessageBg: scale-color(map-get($colors, 'blue'), $lightness: 90%) !default;
$infoMessageBorder: solid map-get($colors, 'blue') !default;
$infoMessageTextColor: map-get($colors, 'blue') !default;
$infoMessageIconColor: map-get($colors, 'blue') !default;

$successMessageBg: scale-color(
  map-get($colors, 'green'),
  $lightness: 90%
) !default;
$successMessageBorder: solid map-get($colors, 'green') !default;
$successMessageTextColor: map-get($colors, 'green') !default;
$successMessageIconColor: map-get($colors, 'green') !default;

$warningMessageBg: scale-color(
  map-get($colors, 'peach'),
  $lightness: 90%
) !default;
$warningMessageBorder: solid map-get($colors, 'peach') !default;
$warningMessageTextColor: map-get($colors, 'peach') !default;
$warningMessageIconColor: map-get($colors, 'peach') !default;

$errorMessageBg: scale-color(
  map-get($colors, 'crimson'),
  $lightness: 90%
) !default;
$errorMessageBorder: solid map-get($colors, 'crimson') !default;
$errorMessageTextColor: map-get($colors, 'crimson') !default;
$errorMessageIconColor: map-get($colors, 'crimson') !default;

//overlays
$overlayContentBorder: 0 none !default;
$overlayContentBg: $panelContentBg !default;
$overlayContainerShadow: 0 1px 3px rgba(0, 0, 0, 0.3) !default;

//dialog
$dialogHeaderBg: $shade000 !default;
$dialogHeaderBorder: 0 none !default;
$dialogHeaderTextColor: $shade600 !default;
$dialogHeaderFontWeight: 700 !default;
$dialogHeaderFontSize: 1.25rem !default;
$dialogHeaderPadding: 1.5rem !default;
$dialogContentBg: #f7f7f7 !default;
$dialogContentPadding: 0 1.5rem 2rem 1.5rem !default;
$dialogFooterBorder: 0 none !default;
$dialogFooterPadding: 0 1.5rem 1.5rem 1.5rem !default;

//confirmpopup
$confirmPopupContentPadding: $panelContentPadding;
$confirmPopupFooterPadding: 0 1.25rem 1.25rem 1.25rem;

//tooltip
$tooltipBg: $shade700 !default;
$tooltipTextColor: $shade000 !default;
$tooltipPadding: $inputPadding !default;

//steps
$stepsItemBg: $shade000 !default;
$stepsItemBorder: 1px solid $shade200 !default;
$stepsItemTextColor: $shade600 !default;
$stepsItemNumberWidth: 2rem !default;
$stepsItemNumberHeight: 2rem !default;
$stepsItemNumberFontSize: 1.143rem !default;
$stepsItemNumberColor: $shade700 !default;
$stepsItemNumberBorderRadius: 50% !default;
$stepsItemActiveFontWeight: 700 !default;

//progressbar
$progressBarHeight: 1.5rem !default;
$progressBarBorder: 0 none !default;
$progressBarBg: $shade300 !default;
$progressBarValueBg: $primaryColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth: 12.5rem !default;
$menuBg: $shade100 !default;
$menuBorder: 1px solid $shade300 !default;
$menuTextColor: $shade700 !default;
$menuitemPadding: 0.75rem 1.25rem !default;
$menuitemBorderRadius: 0 !default;
$menuitemTextColor: $shade700 !default;
$menuitemIconColor: $shade600 !default;
$menuitemTextHoverColor: $shade700 !default;
$menuitemIconHoverColor: $shade600 !default;
$menuitemHoverBg: $shade200 !default;
$menuitemTextActiveColor: $highlightTextColor !default;
$menuitemIconActiveColor: $highlightTextColor !default;
$menuitemActiveBg: $highlightBg !default;
$menuitemSubmenuIconFontSize: 0.875rem !default;
$submenuHeaderMargin: 0 !default;
$submenuHeaderPadding: 0.75rem 1.25rem !default;
$submenuHeaderBg: $shade100 !default;
$submenuHeaderTextColor: $shade600 !default;
$submenuHeaderBorderRadius: 0 !default;
$submenuHeaderFontWeight: 700 !default;
$overlayMenuBg: $menuBg !default;
$overlayMenuBorder: 0 none !default;
$overlayMenuShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !default;
$verticalMenuPadding: 0.25rem 0 !default;
$menuSeparatorMargin: 0.25rem 0 !default;

$breadcrumbPadding: 1rem !default;
$breadcrumbBg: $menuBg !default;
$breadcrumbBorder: $menuBorder !default;
$breadcrumbItemTextColor: $menuitemTextColor !default;
$breadcrumbItemIconColor: $menuitemIconColor !default;
$breadcrumbLastItemTextColor: $menuitemTextColor !default;
$breadcrumbLastItemIconColor: $menuitemIconColor !default;
$breadcrumbSeparatorColor: $menuitemTextColor !default;

$horizontalMenuPadding: 0.5rem !default;
$horizontalMenuBg: $shade100 !default;
$horizontalMenuBorder: $menuBorder !default;
$horizontalMenuTextColor: $menuTextColor !default;
$horizontalMenuRootMenuitemPadding: $menuitemPadding !default;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius !default;
$horizontalMenuRootMenuitemTextColor: $menuitemTextColor !default;
$horizontalMenuRootMenuitemIconColor: $menuitemIconColor !default;
$horizontalMenuRootMenuitemTextHoverColor: $menuitemTextHoverColor !default;
$horizontalMenuRootMenuitemIconHoverColor: $menuitemIconHoverColor !default;
$horizontalMenuRootMenuitemHoverBg: $menuitemHoverBg !default;
$horizontalMenuRootMenuitemTextActiveColor: $menuitemTextActiveColor !default;
$horizontalMenuRootMenuitemIconActiveColor: $menuitemIconActiveColor !default;
$horizontalMenuRootMenuitemActiveBg: $menuitemActiveBg !default;

//badge and tag
$badgeBg: $primaryColor !default;
$badgeTextColor: $primaryTextColor !default;
$badgeMinWidth: 1.5rem !default;
$badgeHeight: 1.5rem !default;
$badgeFontWeight: 700 !default;
$badgeFontSize: 0.75rem !default;

$tagPadding: 0.25rem 0.4rem !default;

//carousel
$carouselIndicatorsPadding: 1rem !default;
$carouselIndicatorBg: $shade400 !default;
$carouselIndicatorHoverBg: $shade500 !default;
$carouselIndicatorBorderRadius: 0 !default;
$carouselIndicatorWidth: 2rem !default;
$carouselIndicatorHeight: 0.5rem !default;

//galleria
$galleriaMaskBg: rgba(0, 0, 0, 0.9) !default;
$galleriaCloseIconMargin: 0.5rem !default;
$galleriaCloseIconFontSize: 2rem !default;
$galleriaCloseIconBg: transparent !default;
$galleriaCloseIconColor: $shade100 !default;
$galleriaCloseIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaCloseIconHoverColor: $shade100 !default;
$galleriaCloseIconWidth: 4rem !default;
$galleriaCloseIconHeight: 4rem !default;
$galleriaCloseIconBorderRadius: 50% !default;

$galleriaItemNavigatorBg: transparent !default;
$galleriaItemNavigatorColor: $shade100 !default;
$galleriaItemNavigatorMargin: 0 0.5rem !default;
$galleriaItemNavigatorFontSize: 2rem !default;
$galleriaItemNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaItemNavigatorHoverColor: $shade100 !default;
$galleriaItemNavigatorWidth: 4rem !default;
$galleriaItemNavigatorHeight: 4rem !default;
$galleriaItemNavigatorBorderRadius: $borderRadius !default;

$galleriaCaptionBg: rgba(0, 0, 0, 0.5) !default;
$galleriaCaptionTextColor: $shade100 !default;
$galleriaCaptionPadding: 1rem !default;

$galleriaIndicatorsPadding: 1rem !default;
$galleriaIndicatorBg: $shade400 !default;
$galleriaIndicatorHoverBg: $shade500 !default;
$galleriaIndicatorBorderRadius: 50% !default;
$galleriaIndicatorWidth: 1rem !default;
$galleriaIndicatorHeight: 1rem !default;
$galleriaIndicatorsBgOnItem: rgba(0, 0, 0, 0.5) !default;
$galleriaIndicatorBgOnItem: rgba(255, 255, 255, 0.4) !default;
$galleriaIndicatorHoverBgOnItem: rgba(255, 255, 255, 0.6) !default;

$galleriaThumbnailContainerBg: rgba(0, 0, 0, 0.9) !default;
$galleriaThumbnailContainerPadding: 1rem 0.25rem !default;
$galleriaThumbnailNavigatorBg: transparent !default;
$galleriaThumbnailNavigatorColor: $shade100 !default;
$galleriaThumbnailNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaThumbnailNavigatorHoverColor: $shade100 !default;
$galleriaThumbnailNavigatorBorderRadius: 50% !default;
$galleriaThumbnailNavigatorWidth: 2rem !default;
$galleriaThumbnailNavigatorHeight: 2rem !default;

//divider
$dividerHorizontalMargin: 1.25rem 0;
$dividerHorizontalPadding: 0 1.25rem;
$dividerVerticalMargin: 0 1.25rem;
$dividerVerticalPadding: 1.25rem 0;
$dividerSize: 1px;
$dividerColor: $shade300;

//avatar
$avatarBg: $shade300;
$avatarTextColor: $textColor;

//chip
$chipBg: $shade300;
$chipTextColor: $textColor;
$chipBorderRadius: 16px;

//scrollTop
$scrollTopBg: rgba(0, 0, 0, 0.7);
$scrollTopHoverBg: rgba(0, 0, 0, 0.8);
$scrollTopWidth: 3rem;
$scrollTopHeight: 3rem;
$scrollTopBorderRadius: 50%;
$scrollTopFontSize: 1.5rem;
$scrollTopTextColor: $shade100;

//skeleton
$skeletonBg: $shade300;
$skeletonAnimationBg: rgba(255, 255, 255, 0.4);

//splitter
$splitterGutterBg: $shade100;
$splitterGutterHandleBg: $shade300;

:root {
  --surface-a: #{$shade000};
  --surface-b: #{$shade100};
  --surface-c: #{$shade200};
  --surface-d: #{$shade300};
  --surface-e: #{$shade000};
  --surface-f: #{$shade000};
  --text-color: #{$shade700};
  --text-color-secondary: #{$shade600};
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  --font-family: #{$fontFamily};
  --surface-0: #{$surface000};
  --surface-50: #{$surface50};
  --surface-100: #{$surface100};
  --surface-200: #{$surface200};
  --surface-300: #{$surface300};
  --surface-400: #{$surface400};
  --surface-500: #{$surface500};
  --surface-600: #{$surface600};
  --surface-700: #{$surface700};
  --surface-800: #{$surface800};
  --surface-900: #{$surface900};
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
}
