/* Customizations to the designer theme should be defined here */
@import '../theme/variables';

.p-datepicker {
  .p-datepicker-header {
    .p-datepicker-prev,
    .p-datepicker-next {
      color: $primaryTextColor;

      &:enabled:hover {
        color: $primaryTextColor;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .p-datepicker-buttonbar {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    .p-button {
      color: $primaryTextColor;

      &:enabled:hover {
        color: $primaryTextColor;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .p-timepicker {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    .p-link {
      color: $primaryTextColor;

      &:enabled:hover {
        color: $primaryTextColor;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  table {
    font-size: $fontSize;
    margin: $calendarTableMargin;

    th {
      color: rgba(255, 255, 255, 0.7);
    }

    td {
      > span {
        &.p-highlight {
          color: $primaryTextColor;
          background: rgba(255, 255, 255, 0.3);
        }
      }

      &.p-datepicker-today {
        > span {
          &.p-highlight {
            color: $primaryTextColor;
            background: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }

  .p-monthpicker {
    .p-monthpicker-month {
      &.p-highlight {
        color: $primaryTextColor;
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.p-button {
  .p-button-label {
    font-weight: 700;
  }
}

.p-carousel {
  .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: $primaryColor;
  }
}

.p-galleria {
  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: $primaryColor;
  }
}

// custom extension
.p-panelmenu .p-panelmenu-header > a {
  padding: 0.65rem !important;
}

.p-panelmenu .p-panelmenu-content {
  border: 0px !important;
  background: $menuBackgroundColor !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.65rem !important;
  padding-left: 2rem !important;
}

.p-panelmenu .p-menu-separator {
  border-top: 1px solid $menuBackgroundColor;
  margin: 0.25rem 0;
}

.p-panelmenu {
  a.p-panelmenu-header-link.active,
  a.p-menuitem-link.active {
    background: $higlightColor !important;
  }

  .p-menuitem-icon {
    color: $menuIconColor;
  }
}

.p-paginator .p-dropdown {
  margin-left: auto !important;
}

.p-menuitem-icon {
  min-width: 30px;
}

.p-datatable-scrollable-body {
  overflow: auto !important;
}

.p-paginator {
  .p-dropdown {
    .p-dropdown-label {
      padding: 6px 0px 6px 10px !important;
    }
  }
}
