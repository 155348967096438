@import '../../scss/theme/variables';
#proof_component {
  .orders-panel{
    .p-card-body {
      padding: 0.5rem 1rem;
      .p-card-content {
        padding: 0.3rem 0;
      }
    }
  }
  .photos-panel {
    .photos-upload-button {
      .p-button-label {
        display: none;
      }
    }
  }
  .p-panel {
    .p-panel-header {
      background-color: $shade600;
      color: $shade000;
    }
    .p-panel-content {
      background-color: $shade300;
      padding: 1rem;
    }
  }
}
.remove-confirmation-dialog {
  .p-dialog-header {
    .p-dialog-header-icons {
      display: none;
    }
    .p-dialog-content, .p-dialog-footer {
      padding: 1.2rem;
    }
  }
}
