@import '../../scss/theme/variables';

#layout-wrapper {
  height: 100%;
  min-width: 100%;
  flex: 1 1 100%;
  max-width: 100%;
}

#content {
  height: 100%;
  max-height: 100%;
  flex: 1 1 100%;
  min-width: 0;
  max-width: 100%;
  overflow: auto;
}

#topbar {
  max-height: 45px;
}

.layout-topbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 998;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  flex: 1 1 100%;

  .p-toolbar {
    padding: 0px;
    height: 100%;
    .p-button.p-button-text.p-button-plain:not(button):not(.p-disabled) {
      &:active, &:hover{
        background-color: transparent;
      }
    }
  }

  .p-toolbar-group-left {
    width: 100%;
    justify-content: space-between;
  }
}
