@import '../../scss/theme/variables';

#schedule_component {
  height: 100%;
  .p-panel {
    .p-panel-header {
      background-color: $shade600;
      color: $shade000;
      .p-button-icon {
        font-size: 0.6rem;
      }
    }

    .p-panel-content {
      background-color: $shade300;
      padding: 1rem;

      .p-card-body {
        padding: 1rem;
      }

      .p-card-content {
        padding: 0.3rem 0;
      }

      .p-card-title {
        margin: 0;
      }
    }
  }

  .schedule-title {
    font-weight: bold;
    font-size: 1.85rem;
  }

  .task-reminder{
    background-color: #FFFFE0;
    color: black;
  }
}
