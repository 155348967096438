@font-face {
  font-family: 'UntitledSans-Light';
  src: local('UntitledSans-Light'),
    url('../../assets/fonts/untitled-sans-web-light.eot') format('eot'),
    url('../../assets/fonts/untitled-sans-web-light.woff') format('woff'),
    url('../../assets/fonts/untitled-sans-web-light.woff2') format('woff2');
}

@font-face {
  font-family: 'UntitledSans-Regular';
  src: local('UntitledSans-Regular'),
    url('../../assets/fonts/untitled-sans-web-regular.eot') format('eot'),
    url('../../assets/fonts/untitled-sans-web-regular.woff') format('woff'),
    url('../../assets/fonts/untitled-sans-web-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'UntitledSans-Bold';
  src: local('UntitledSans-Bold'),
    url('../../assets/fonts/untitled-sans-web-bold.eot') format('eot'),
    url('../../assets/fonts/untitled-sans-web-bold.woff') format('woff'),
    url('../../assets/fonts/untitled-sans-web-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'UntitledSans-Medium';
  src: local('UntitledSans-Medium'),
    url('../../assets/fonts/untitled-sans-web-medium.eot') format('eot'),
    url('../../assets/fonts/untitled-sans-web-medium.woff') format('woff'),
    url('../../assets/fonts/untitled-sans-web-medium.woff2') format('woff2');
}
